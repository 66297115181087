// Here you can add other styles

* {
  font-family: "Montserrat", sans-serif !important;
}
.login button span {
  color: #333 !important;
}
.login button {
  border-color: #333 !important;
}

canvas.identicon {
  border-radius: 25px;
  border: 1px solid #ccc;
  background: #c4bcb2;
}

.c-sidebar {
  background-color: black;
}

.copy-icon {
  width: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.date-filter-wrapper {
  width: 100%;
  padding-right: 5px;
}
.date-filter {
  display: inline-block;
  margin: 10px;
  background-color: white;
  padding: 10px;
  float: right;
}
@media only screen and (max-width: 600px) {
  .ok {
    float: right;
  }
}
